const config = {
  REACT_APP_PROJECT_STATE: "production",
  REACT_APP_DEVELOPMENT_BACKEND_BASE_URL: "https://localhost:5000",
  REACT_APP_PRODUCTION_BACKEND_BASE_URL: "https://api.kalusugapp.com",
  REACT_APP_PRODUCTION_BACKEND_WEBSOCKET: "wss://api.kalusugapp.com",
  REACT_APP_DEVELOPMENT_BACKEND_WEBSOCKET: "wss://localhost:5000",
  REACT_APP_MAPBOX_KEY: "pk.eyJ1IjoiYW1wZWwtMjMiLCJhIjoiY2x2Z2NidzVjMHVjMDJpbnZtMThmNm51MCJ9.xce_TB3zt17jZYgYVG3new",
  REACT_APP_SECRET: "6737c179aa62a98f777d25bb5655af466d59ef287c95979b788944482e2a4a4c",
};

export default config;